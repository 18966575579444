import withRoot from "../utils/withRoot";
import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/Seo";
// import Card from "../components/Card";
import Page from "../components/Page";
// import HomeFeatures from "../components/HomeFeatures";
import Button from "@material-ui/core/Button";
// import Carousel from "../components/Carousel";
// import Avatar from "@material-ui/core/Avatar";
// import { Gift } from "mdi-material-ui";
import withStyles from "@material-ui/styles/withStyles";
import Hidden from '@material-ui/core/Hidden';
// import irukaGIF from '../gifs/iruka-ani12.gif';
import Grid from "@material-ui/core/Grid";
// import BackgroundImage from 'gatsby-background-image';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import topImage from '../images/service.png';
import { clearConfigCache } from "prettier";
import { mergeClasses } from "@material-ui/styles";
import TextField from '@material-ui/core/TextField';
import firebase from "gatsby-plugin-firebase";
// import { withFirebase } from "../components/WithFirebase";
import {navigate} from 'gatsby';
import Image from '../components/ImageDraw';
// import Helmet from "react-helmet";
// import plugin from 'jquery-plugin';
import engine from '../images/推薦エンジン.svg';
import match from '../images/マッチング.svg';
import cancer from '../images/がんAI.svg';
import Iot from '../images/IoTデータ.svg';
import medical from '../images/医療AI.svg';
import insurance from '../images/保険AI.svg';

const flag = typeof window !== 'undefined';

// let auth = null;
let functions = null;
let windowInstance;
let documentInstance;
let jQuery;
if (flag) {
    require("firebase/auth");
    require("firebase/firestore");
    require("firebase/functions");
    jQuery = require('jquery');
    functions = firebase.functions();
    // auth = firebase.auth();
    windowInstance = window;
    documentInstance = document;
}

const requiredValue = [
    'name',
    'content'
];

const notString = [
    'disabled',
    'seo',
    'formDisabled'
];

const theme = createTheme({
    palette: {
        primary: {
            main: '#ffffff'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 538,
            md: 652,
            lg: 1100,
            xl: 2000,
        },
    },
    overrides: {
        MuiFormLabel: {
            root: {
                color: '#fff'
            }
        }
    }
});

const styles = theme => ({
    divider: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
    },
    root: {
        fontWeight: "bold",
        // backgroundColor: '#000'
    }
    // gatsbyImageWrapper: {
    //   [theme.breakpoints.down('sm')]: {
    //     width: 'calc(100vw / 4)',
    //     height: 'calc(30vw / 6)'
    //   },
    //   [theme.breakpoints.down('xs')]: {
    //     width: 0,
    //     height: 0
    //   }
    // }
});

class Service extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            seo: false,
            // scrollTopFlag: false,
            windowHeight: 0,
            siteHeight: 0,
            divTop: {},
            divHeight: {}
        }

        var url = this.props.location.href
        if (typeof(url) === 'string') {
            if (url.indexOf('staging') !== -1) {
                // console.log(this.state.disabled)
                this.state.disabled = true
                // console.log(this.state.disabled)
            } else {
                this.state.seo = true
            }
            // console.log(this.state.seo)
        }
        // console.log(this.props.data.testFixed)
        // authIP(this)
    }

    async componentDidMount() {
        windowInstance.addEventListener('scroll', this.handleScroll);
        if (this.state.disabled) {
            var res = await fetch('/api/authIP', {
                method: 'POST'
            }).then((response) => {
                // console.log(response)
                return response.text();
            });
            // console.log(res)
            if (res === 'Y') {
                // console.log(state.state.disabled)
                this.setState({disabled: false});
            }
        // console.log(state.state.disabled)
        }
        // console.log(jQuery('div.gatsby-image-wrapper'))
        jQuery('div.gatsby-image-wrapper').css('height', '');//.removeAttr('style');
        jQuery('div.gatsby-image-wrapper').css('width', '');
        jQuery('div.gatsby-image-wrapper').css('position', '');
        // jQuery('div.gatsby-image-wrapper').removeAttr('width');
        // document.getElementsByClassName('service-section-text').removeAttr('height')
        // document.getElementsByClassName('service-section-text').removeAttr('width')

        var insertDiv = {}
        var insertHeight = {}
        jQuery('div.animation-init').each(function() {
        insertDiv[jQuery(this).attr('id')] = jQuery(this).offset().top;
        insertHeight[jQuery(this).attr('id')] = jQuery(this).height();
        // console.log(jQuery(this).attr('id'));
        // console.log(jQuery(this).offset().top);
        });


        this.state.divTop = insertDiv
        this.state.divHeight = insertHeight
        // console.log(Object.keys(this.state.divTop))
        // console.log(this.state.divHeight['animation-2-right-title']);
        this.handleScroll();
    }

    renderSeo() {
        return (
            <Seo title="Service">
                <meta
                    name="description"
                    content="AristoBrain株式会社のサービス、制作実績、弊社が関わった案件、プロダクト" 
                />
                <meta
                    name="keywords"
                    content="生命科学AI,生物科学AI,医療AI,行動予測AI,分野融合,企業変革,新規事業,サービス,実績"
                />
            </Seo>
        )
    }


    handleScroll = () => {
        // if (this.state.scrollTopFlag) {
    
        var insertDic = {};
        var insertDiv = {};
        var insertHeight = {};
        var predivTop = Object.keys(this.state.divTop);
        // console.log(predivTop);
        if (this.state.siteHeight !== documentInstance.body.clientHeight) {
          insertDic['siteHeight'] = documentInstance.body.clientHeight;
          insertDic['windowHeight'] = windowInstance.innerHeight;
          jQuery('div.animation-init').each(function() {
            if (predivTop.includes(jQuery(this).attr('id'))) {
              insertDiv[jQuery(this).attr('id')] = jQuery(this).offset().top;
              insertHeight[jQuery(this).attr('id')] = jQuery(this).height();
              // console.log(jQuery(this).attr('id'));
              // console.log(jQuery(this).offset().top);
            }
          });
        }
    
        // var ua = navigator.userAgent;
        // console.log(ua);
        var top = windowInstance.scrollY;
        // // Edge, Safariの場合 
        // if(ua.indexOf('Edge') !== -1 || (windowInstance.chrome || 'WebkitAppearance' in documentInstance.documentElement.style)) {
        //   top = jQuery('body').scrollTop();
        // // Chrome, IE, Firefoxの場合
        // } else {
        //   top = jQuery('html').scrollTop();
        // }
        // console.log(top);
        // console.log(windowInstance.innerHeight)
        // console.log(insertDic);
        if (Object.keys(insertDic).length > 0) {
          if (Object.keys(insertDiv).length > 0) {
            insertDic['divTop'] = insertDiv
            insertDic['divHeight'] = insertHeight
          }
          this.setState(insertDic)
          // console.log(this.state.divTop)
          // console.log(this.state)
        }
    
        // console.log(top + this.state.windowHeight);
        // console.log(this.state.divTop['animation-2-right-title']);
        // console.log(top);
        // console.log(this.state.divTop['animation-2-right-title'] + this.state.divHeight['animation-2-right-title']);
    
        var dic = this.state;
        predivTop.forEach(function(key) {
          var number = Number(key.split('-')[1]);
          // console.log(number)
          // var direction, elem;
          // [direction, elem] = key.split('-').slice(2);
          // console.log(direction)
          // console.log(elem)
          // console.log(top + dic.windowHeight);
          // console.log(dic.divTop[key]);
          // console.log(top);
          // console.log(dic.divTop[key] + dic.divHeight[key]);
          if ((top + dic.windowHeight >= dic.divTop[key]) && (top <= dic.divTop[key] + dic.divHeight[key])) {
            if (number === 1) {
                jQuery('div#' + key).addClass('fade-in-service-image');
                jQuery('div#' + key + ' > div > div > h1').addClass('fade-in-news-title');
                jQuery('div#' + key + ' > div > div > p').addClass('fade-in-about-text');
                jQuery('div#' + key + ' > div > div.under-bar-text').addClass('fade-in-service-text');
            } else if (number === 4 || number === 2) {
                jQuery('div#' + key).addClass('fade-in-service-article');
            } else if (number === 3) {
                jQuery('div#' + key).css('opacity', 1);
                ['first', 'second', 'third'].forEach(function(article) {
                    // console.log(article)
                    // console.log(jQuery('div#' + key + ' > div#service-article-' + article))
                    jQuery('div#' + key + ' > div#service-article-' + article).addClass('fade-in-service-' + article);
                });
            } else if (number >= 5) {
                jQuery('div#' + key).addClass('up-service-logo');
            }
            // console.log(top + dic.windowHeight);
            // console.log(dic.divTop[key]);
            // console.log(top);
            // console.log(dic.divTop[key] + dic.divHeight[key]);
            delete dic.divTop[key];
            delete dic.divHeight[key];
          }
        });
    
        this.setState(dic);
    
        // insertDic['divTop'] = insertDiv
        // this.setState(insertDic)
        // console.log(this.state.siteHeight)
        // console.log(this.state.windowHeight)
        // console.log(this.state.divTop)
        // console.log(windowInstance.scrollY)
    
    
    
        // } else {
        //   this.setState({scrollTopFlag: true});
        // }
    }


  render() {
    // const products = props.data.allMarkdownRemark.edges;
    const {disabled} = this.state;
    // console.log(disabled)
    // if (this.props.location.href.indexOf('staging') != -1) {
    //   disabled = true
    // }

    if (disabled) {
      // console.log(this.state.disabled)
      return (
        <h1>You can't enter in this pages.</h1>
      );
    } else {
      // console.log(theme.gatsbyImageWrapper)
      // console.log(this.props.classes.gatsbyImageWrapper)
      return (
        <Page url={this.props.location.pathname}>
            <ThemeProvider theme={theme}>
                {this.state.seo && this.renderSeo()}
                <div id='service-1' className='animation-init' style={{backgroundImage: `url(${topImage})`, backgroundSize: 'cover', backgroundPosition: 'left top', display: 'flow-root', padding: '0 3% 10% 0'}}>
                    <div style={{margin: 0, padding: 0}}>
                        <div className='under-bar-title'>
                            <h1>Service</h1>
                            <p>
                                サービス
                            </p>
                        </div>
                        <div className='under-bar-text'>
                            <p className='thread-text'>生命科学と情報科学を使った新規事業創出・業務改善サービス</p>
                            <p className='tbody-text' style={{marginTop: 0, marginBottom: '15%'}}>
                                若さと熱量と勤勉さが売りです。今まで関わった十数件の案件はすべて途中で断念することはなく、パートナーからのクレームも0件でした。​現状のお仕事のリピート率は約3割です。
                            </p>
                        </div>
                    </div>
                    <Grid
                        container
                        direction='row'
                        justify="space-between"
                        spacing={0}
                        alignItems='stretch'
                        style={{color: '#fff'}}
                    >
                        <Grid md={5} xs={12} id='service-2' className='animation-init'>
                            <h4 className='service-section-text'>制作事例</h4>
                        </Grid>
                        <Grid md={7} xs={12} id='service-3' className='service-right-article animation-init'>
                            <div id='service-article-first' className='service-article'>
                                {/* <div style={{float: 'left'}}> */}
                                <Image filename='pos開発.png' classN='service-img' />
                                {/* </div> */}
                                <div className='right-right-article' >
                                    <div>
                                        <p className='tbody-text' >【大手IT企業様】</p>
                                        <p className='thread-text' >POSデータ アルゴリズム開発</p>
                                        <p className='thread-text' >POSデータに基づいた購買周期予測アルゴリズム開発</p>
                                        <p className='tbody-text' >顧客データに基づいたレコメンドエンジン、POSデータに基づいた顧客・商品毎の購買周期予測アルゴリズム開発に成功。これまでただ埋もれていた膨大なデータを、スーパーマーケットの売り上げアップにつながる価値ある資産へと生まれ変わりました。</p>
                                    </div>
                                </div>
                            </div>
                            <div id='service-article-second' className='service-article'>
                                {/* <div style={{float: 'left'}}> */}
                                <Image filename='magee.png' classN='service-img' />
                                {/* </div> */}
                                <div className='right-right-article'>
                                    <div>
                                        <p className='tbody-text'>【株式会社アイディーズ様】</p>
                                        <p className='thread-text'>MageeData Dojo</p>
                                        <p className='thread-text'>データサイエンティスト育成教育ソフト開発</p>
                                        <p className='tbody-text'>３年以上行ってきた独自のデータサイエンティスト育成講座のノウハウを生かし、沖縄県の株式会社アイディーズ様が運営するデータサイエンス講座「MageeData Dojo」への教育動画コンテンツの提供を行いました。</p>
                                    </div>
                                    <Link to='https://www.id-s.net/' target="_blank" rel="noopener noreferrer" style={{color: '#fff', textDecoration: 'underline'}}><p className='tbody-text' style={{margin: 0,  fontSize: 'max(calc(1vw / 2), 10px)', textAlign: 'right'}}>ウェブサイトはこちら</p></Link>
                                </div>
                            </div>
                            <div id='service-article-third' className='service-article' style={{paddingBottom: 0}}>
                                {/* <div style={{float: 'left'}}> */}
                                <Image filename='新規医療診断.png' classN='service-img' />
                                {/* </div> */}
                                <div className='right-right-article'>
                                    <div>
                                        <p className='tbody-text'>【国立がんセンター東病棟様】</p>
                                        <p className='thread-text'>人工知能を用いた新規診断・治療アルゴリズムの開発研究</p>
                                        <p className='thread-text'>がん診断アルゴリズム開発研究</p>
                                        <p className='tbody-text'>国立がんセンター東病院様との共同研究に参加し、癌診断アルゴリズムの開発に関する研究を行いました。研究の成果は、2019年4月にサンフランシスコで行われた2019 Google Cloud Nextで発表されました。</p>
                                    </div>
                                    <Link to='https://www.ncc.go.jp/jp/about/research_promotion/study/list/2018-304.pdf' target="_blank" rel="noopener noreferrer" style={{color: '#fff', textDecoration: 'underline'}}><p className='tbody-text' style={{margin: 0,  fontSize: 'max(calc(1vw / 2), 10px)', textAlign: 'right'}}>プレリリースはこちら</p></Link>
                                    <Link to='https://www.youtube.com/watch?v=iO4x1aJw_Jc'target="_blank" rel="noopener noreferrer" style={{color: '#fff', textDecoration: 'underline'}}><p className='tbody-text' style={{margin: 0,  fontSize: 'max(calc(1vw / 2), 10px)', textAlign: 'right'}}>発表の様子はこちら</p></Link>
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={12} id='service-4' className='animation-init'>
                            <h4 className='service-section-text' style={{paddingTop: '15%', display: 'inline-block', paddingBottom: '3%'}}>PRODUCTS</h4>
                        </Grid>
                        
                        <Grid lg={2} md={2} sm={2} xs={0}></Grid>

                        <Grid lg={3} md={3} sm={4} xs={12} id='service-5' className='simple-logo animation-init'>
                            <img src={engine} />
                            <p className='thread-text'>商品推薦エンジン開発</p>
                            <p className='tbody-text' >最新の知見を用いた商品推薦エンジンを開発します</p>
                        </Grid>
                        <Grid lg={3} md={3} sm={4} xs={12} id='service-6' className='simple-logo animation-init'>
                            <img src={match} />
                            <p className='thread-text'>マッチングエンジン開発</p>
                            <p className='tbody-text'>​個と個、個と組織、組織と組織のマッチングを自動で行えるようにします</p>
                        </Grid>

                        <Hidden mdUp>
                            <Grid sm={2} xs={0}></Grid>
                            <Grid sm={2} xs={0}></Grid>
                        </Hidden>

                        <Grid lg={3} md={3} sm={4} xs={12} id='service-7' className='simple-logo animation-init'>
                            <img src={cancer} />
                            <p className='thread-text'>がん分野でのAI開発</p>
                            <p className='tbody-text'>病理画像、CT画像、MRI画像等から各種タスクを高精度で実行可能にします</p>
                        </Grid>

                        <Grid lg={1} md={1} sm={0} xs={0}></Grid>
                        
                        <Grid lg={2} md={2} sm={0} xs={0}></Grid>

                        <Grid lg={3} md={3} sm={4} xs={12} id='service-8' className='simple-logo animation-init'>
                            <img src={Iot} />
                            <p className='thread-text'>IoTデータ解析</p>
                            <p className='tbody-text'>IoTセンサデータ等の時系列データから人の行動判断等のタスクを実行可能にします</p>
                        </Grid>

                        <Hidden mdUp>
                            <Grid sm={2} xs={0}></Grid>
                            <Grid sm={2} xs={0}></Grid>
                        </Hidden>

                        <Grid lg={3} md={3} sm={4} xs={12} id='service-9' className='simple-logo animation-init'>
                            <img src={medical} />
                            <p className='thread-text'>再生医療分野でのAI開発</p>
                            <p className='tbody-text'>培養細胞のSemantic SegmentationやInstance Segmentationを実行可能にします</p>
                        </Grid>

                        <Grid lg={3} md={3} sm={4} xs={12} id='service-10' className='simple-logo animation-init'>
                            <img src={insurance} />
                            <p className='thread-text'>​保険分野でのAI開発</p>
                            <p className='tbody-text'>個人や組織ごとに、どんな保険が適切で、どのようなステップ・人がどのように販売するべきかを提案することを実行可能にします</p>
                        </Grid>
                        <Grid lg={1} md={1} sm={2} xs={0}></Grid>
                    </Grid>
                </div>
            </ThemeProvider>
        </Page>
      );
    }
  }
};

export const query = graphql`
  query {
    allFile(filter: { extension: { eq: "jpg" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/products/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            image {
              publicURL
            }
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
  }
`;

export default withRoot(withStyles(styles)(Service));